<template>
  <div class="home">
    <main>
      <home-banner-slider />
      <discount-list />
      <section v-if="stocks.data.length" class="stocks">
        <div class="container">
          <div class="header-block">
            <h2 class="title">{{ $t("stock") }}</h2>
            <locale-router-link to="stocks" class="header-block__link">{{
              $t("showMore")
            }}</locale-router-link>
          </div>
          <stocks-list v-if="stocks.data" :items="stocks.data" />

          <button
            v-if="!stocks.isLastPage"
            class="stocks__button"
            @click="nextStocksPage"
          >
            {{ $t("seeAll") }}
          </button>
        </div>
      </section>

      <weekly-products-list :page-size="5" />

      <section class="magnum-club">
        <div class="container">
          <div class="magnum-club__content">
            <div class="magnum-club__left">
              <div class="magnum-club__left-wrapper">
                <img
                  class="magnum-club__logo"
                  src="@/assets/img/icons/magnum-club__logo.svg"
                  alt=""
                />
                <div class="magnum-club__title">{{ $t("loyaltyCard") }}</div>
                <locale-router-link
                  to="magnumclub"
                  :href="''"
                  class="magnum-club__btn"
                >
                  {{ $t("more") }}
                </locale-router-link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="magnum-club-mobile">
        <div class="container">
          <div class="magnum-club-mobile__content">
            <img
              class="magnum-club-mobile__logo"
              src="@/assets/img/magnum-club/magnum-club__red.svg"
              alt=""
            />
            <h4 class="magnum-club-mobile__title">{{ $t("loyaltyCard") }}</h4>
            <p class="magnum-club-mobile__text">
              {{ $t("clubLoyalty") }}
            </p>
            <locale-router-link
              to="magnumclub"
              :href="''"
              class="magnum-club-mobile__btn"
            >
              {{ $t("more") }}
            </locale-router-link>
            <img
              class="magnum-club-mobile__img"
              src="@/assets/img/magnum-club/magnum-club__cart.png"
              alt=""
            />
          </div>
        </div>
      </section>
      <shops-map
        v-if="shopsList"
        :is-show-shop-types="true"
        :shops-list="shopsList"
        :key="selectedCity.id"
      />
      <div v-else class="loader-mini__container">
        <div class="loader-mini"></div>
      </div>
      <section class="banner-vacancy" @click.prevent="redirectVacancy">
        <img
          class="banner-text-img"
          src="@/assets/img/icons/banner-text.svg"
          alt="banner text"
        />
        <div class="container">
          <div class="banner-vacancy__content">
            <div class="banner-vacancy__header">
              <h2>
                {{ $t("careerMagnum") }}
                <span>
                  {{ $t("leaveRequest") }}
                </span>
              </h2>
            </div>
            <button class="btn-gray">{{ $t("submitApplication") }}</button>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getPromos } from "@/api";
import { getShopsByFilters } from "@/api/shop";
export default {
  name: "Home",
  components: {
    HomeBannerSlider: () => import("@/components/sliders/HomeBannerSlider.vue"),
    DiscountList: () => import("@/components/discount/DiscountList.vue"),
    WeeklyProductsList: () =>
      import("@/components/products/WeeklyProductsList.vue"),
    StocksList: () => import("@/components/stocks/StocksList.vue"),
    ShopsMap: () => import("@/components/maps/ShopsMap.vue"),
  },
  data() {
    return {
      stocks: {
        page: 1,
        pageSize: 3,
        data: null,
        isLastPage: false,
      },
      shopsList: null,
    };
  },
  computed: {
    ...mapState("cityModule", ["selectedCity"]),
    swiper() {
      return this.$refs?.swiperBannersRef;
    },
    cityId() {
      return this.$i18n.locale === "kz"
        ? this.selectedCity?.attributes.localizations?.data[0].id
        : this.selectedCity.id;
    },
  },
  watch: {
    selectedCity: {
      handler() {
        getShopsByFilters(this.selectedCity.id).then((shops) => {
          this.shopsList = shops;
        });
        if (!this.stocks.data) {
          getPromos(
            { type: "current" },
            {
              page: this.stocks.page,
              pageSize: this.stocks.pageSize,
            }
          ).then((stocks) => {
            this.stocks.data = stocks.data;
            if (this.stocks.data.length < 3) {
              this.stocks.isLastPage = true;
            }
          });
        }
      },
    },
  },
  created() {
    if (this.selectedCity?.id) {
      getPromos(
        { type: "current" },
        {
          page: this.stocks.page,
          pageSize: this.stocks.pageSize,
        }
      ).then((stocks) => {
        this.stocks.data = stocks.data;
        if (this.stocks.data.length < 3) {
          this.stocks.isLastPage = true;
        }
      });
    }

    if (!this.shopsList && this.selectedCity?.id) {
      getShopsByFilters(this.selectedCity?.id).then((shops) => {
        this.shopsList = shops;
      });
    }
  },
  destroyed() {
    // eventBus.$off("gtm");
  },
  methods: {
    getClubLink(link) {
      window.location.href = link;
    },
    redirectVacancy() {
      window.location.href =
        this.$i18n.locale === "ru"
          ? "https://magnum.jobster.uz/"
          : "https://magnum.jobster.uz/uz";
    },
    nextStocksPage() {
      this.stocks.page++;
      getPromos(
        { type: "current" },
        {
          page: this.stocks.page,
          pageSize: this.stocks.pageSize,
        }
      ).then((stocks) => {
        this.stocks.data = [...this.stocks.data, ...stocks.data];
        if (this.stocks.page === stocks.meta.pagination.pageCount) {
          this.stocks.isLastPage = true;
        }
      });
    },
  },
};
</script>

<style scoped>
.loader__container {
  margin-top: 150px;
}
@media (max-width: 1250px) {
  .loader__container {
    margin-top: 100px;
  }
}
@media (max-width: 600px) {
  .loader__container {
    margin-top: 60px;
  }
}
</style>
