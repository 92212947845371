import Vue from "vue";

export const eventBus = new Vue();

export default {
  getAbsolutePath: (path) => process.env.VUE_APP_API_URL + path,
};

const mapItemsToGtm = (payload) => {
  if (Array.isArray(payload.items)) {
    return payload?.items?.map((item, index) => {
      return {
        item_name: item.attributes.name, // Названия продукта //name
        item_id: item.id, // идентификатор продукта//id
        price: item.attributes.final_price, //цена//final_price
        currency: "KZT", //валюта//всегда КЗТ
        item_category: payload.category, // Наивысшая категория для товара, по которому  был клик со списка//главная или каталог скидок или страница акции
        item_list_name: payload.listName, // название списка, в котором пользователь совершил клик по товару//discount_type
        item_list_id: item.attributes.discount_type, // Идентификатор списка, в котором пользователь совершил клик по товару//discount_type id
        index: index, // номер товара в списке
        quantity: "1", // количество товара
      };
    });
  }
};
const mapBannersToGtm = (payload) => {
  if (Array.isArray(payload.items)) {
    return payload?.items?.map((item) => {
      return {
        item_name: item.attributes.name
          ? item.attributes.name
          : item.attributes.header,
        item_id: item.id,
      };
    });
  }
};

/**
 *
 * @param {*} payload {type:string|search|ecommerce, items:type Array, event:string}
 * @returns undefined
 */

export const gtmDataLayer = (payload) => {
  console.log("START");
  const dataLayer = window.dataLayer || [];
  console.log("mapBannersToGtm", mapItemsToGtm(payload));
  // if (
  //   payload.items &&
  //   !Array.isArray(payload?.items) &&
  //   payload.event !== "search"
  // ) {
  //   throw new Error("ITEMS must be array");
  // }
  const term = {
    event: payload.event || "no_event",
  };
  switch (payload.type) {
    case "search":
      term.type_search = payload.typeSearch;
      term.text_search = payload.textSearch;
      console.log("DL before push", dataLayer);
      dataLayer.push(term);
      console.log("DL after push", dataLayer);
      return;
    case "ecommerce":
      term.ecommerce = {
        items: mapItemsToGtm(payload),
      };
      break;
    case "shop":
      term.name = payload.name;
      term.city_show = payload.city;
      break;
    case "select_promotion":
      term.ecommerce = {
        items: mapBannersToGtm(payload),
      };
      break;
  }
  console.log("DL before push", dataLayer);
  dataLayer.push(term);
  console.log("DL after push", dataLayer);
};
